
import * as ipxRuntime$h9c3T2RcKY from '/home/curie-frontend/www/releases/20241114130700/node_modules/@nuxt/image/dist/runtime/providers/ipx'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1536,
    "2xl": 1536
  },
  "presets": {
    "default": {
      "modifiers": {
        "width": 505,
        "height": 305,
        "fit": "cover",
        "format": "webp",
        "quality": "70"
      }
    },
    "index_hero_desktop": {
      "modifiers": {
        "width": 1920,
        "height": 1080,
        "fit": "cover",
        "format": "webp",
        "quality": "70"
      }
    },
    "index_hero_mobile": {
      "modifiers": {
        "width": 400,
        "height": 850,
        "fit": "cover",
        "format": "webp",
        "quality": "70"
      }
    },
    "index_search": {
      "modifiers": {
        "width": 505,
        "height": 305,
        "fit": "cover",
        "format": "webp",
        "quality": "70"
      }
    },
    "index_edito_small": {
      "modifiers": {
        "width": 344,
        "height": 220,
        "fit": "cover",
        "format": "webp",
        "quality": "70"
      }
    },
    "index_edito_large": {
      "modifiers": {
        "width": 769,
        "height": 552,
        "fit": "cover",
        "format": "webp",
        "quality": "70"
      }
    },
    "index_action_logo": {
      "modifiers": {
        "height": 95,
        "fit": "contain",
        "format": "webp",
        "quality": "70"
      }
    },
    "index_project": {
      "modifiers": {
        "width": 680,
        "height": 340,
        "fit": "cover",
        "format": "webp",
        "quality": "70"
      }
    },
    "index_cooperation": {
      "modifiers": {
        "width": 470,
        "height": 460,
        "fit": "cover",
        "format": "webp",
        "quality": "70"
      }
    },
    "index_logo_cooperation": {
      "modifiers": {
        "height": 50,
        "fit": "contain",
        "format": "webp",
        "quality": "70"
      }
    },
    "field_header": {
      "modifiers": {
        "width": 650,
        "height": 415,
        "fit": "cover",
        "format": "webp",
        "quality": "70"
      }
    },
    "paragraph_columns": {
      "modifiers": {
        "width": 1500,
        "height": 950,
        "fit": "cover",
        "format": "webp",
        "quality": "70"
      }
    },
    "paragraph_push_2": {
      "modifiers": {
        "width": 700,
        "height": 440,
        "fit": "cover",
        "format": "webp",
        "quality": "70"
      }
    },
    "paragraph_push_3": {
      "modifiers": {
        "width": 445,
        "height": 280,
        "fit": "cover",
        "format": "webp",
        "quality": "70"
      }
    },
    "paragraph_logo": {
      "modifiers": {
        "width": 75,
        "fit": "contain",
        "format": "webp",
        "quality": "70"
      }
    },
    "paragraph_thumbnail": {
      "modifiers": {
        "width": 230,
        "height": 230,
        "fit": "cover",
        "format": "webp",
        "quality": "70"
      }
    },
    "paragraph_visual_legend": {
      "modifiers": {
        "width": 1520,
        "height": 970,
        "fit": "cover",
        "format": "webp",
        "quality": "70"
      }
    },
    "paragraph_text_image": {
      "modifiers": {
        "width": 725,
        "height": 460,
        "fit": "cover",
        "format": "webp",
        "quality": "70"
      }
    },
    "paragraph_link": {
      "modifiers": {
        "width": 40,
        "height": 40,
        "fit": "outside",
        "format": "webp"
      }
    },
    "paragraph_carousel_persons": {
      "modifiers": {
        "width": 260,
        "height": 260,
        "fit": "cover",
        "format": "webp",
        "quality": "70"
      }
    },
    "paragraph_testimony": {
      "modifiers": {
        "width": 268,
        "height": 268,
        "fit": "cover",
        "format": "webp",
        "quality": "70"
      }
    },
    "alert_image": {
      "modifiers": {
        "width": 100,
        "height": 100,
        "fit": "cover",
        "format": "webp",
        "quality": "70"
      }
    },
    "team_partners": {
      "modifiers": {
        "width": 90,
        "height": 90,
        "fit": "cover",
        "format": "webp",
        "quality": "70"
      }
    },
    "team_presentation": {
      "modifiers": {
        "width": 900,
        "fit": "cover",
        "format": "webp",
        "quality": "70"
      }
    },
    "team_members_header": {
      "modifiers": {
        "width": 140,
        "height": 140,
        "fit": "cover",
        "format": "webp",
        "quality": "70"
      }
    },
    "team_members": {
      "modifiers": {
        "width": 210,
        "height": 210,
        "fit": "cover",
        "format": "webp",
        "quality": "70"
      }
    },
    "team_actuality": {
      "modifiers": {
        "width": 430,
        "height": 270,
        "fit": "cover",
        "format": "webp",
        "quality": "70"
      }
    },
    "actuality": {
      "modifiers": {
        "width": 235,
        "height": 150,
        "fit": "cover",
        "format": "webp",
        "quality": "70"
      }
    },
    "field_hub_header": {
      "modifiers": {
        "width": 1300,
        "height": 490,
        "fit": "cover",
        "format": "webp",
        "quality": "70"
      }
    },
    "field_hub_header_mobile": {
      "modifiers": {
        "width": 390,
        "height": 246,
        "fit": "cover",
        "format": "webp",
        "quality": "70"
      }
    },
    "person_side": {
      "modifiers": {
        "width": 380,
        "height": 380,
        "fit": "cover",
        "format": "webp",
        "quality": "70"
      }
    },
    "image_modal": {
      "modifiers": {
        "width": 680,
        "height": 540,
        "fit": "cover",
        "format": "webp",
        "quality": "70"
      }
    },
    "unit_list": {
      "modifiers": {
        "width": 94,
        "height": 94,
        "fit": "cover",
        "format": "webp",
        "quality": "70"
      }
    },
    "card_cancer_list": {
      "modifiers": {
        "width": 134,
        "height": 150,
        "fit": "cover",
        "format": "webp",
        "quality": "70"
      }
    },
    "testimony_list": {
      "modifiers": {
        "width": 134,
        "height": 150,
        "fit": "cover",
        "format": "webp",
        "quality": "70"
      }
    },
    "person_list": {
      "modifiers": {
        "width": 150,
        "height": 150,
        "fit": "cover",
        "format": "webp",
        "quality": "70"
      }
    },
    "hub_list": {
      "modifiers": {
        "width": 134,
        "height": 150,
        "fit": "cover",
        "format": "webp",
        "quality": "70"
      }
    },
    "cms_list": {
      "modifiers": {
        "width": 134,
        "height": 150,
        "fit": "cover",
        "format": "webp",
        "quality": "70"
      }
    }
  },
  "provider": "ipx",
  "domains": [
    "cdn.curie.fr"
  ],
  "alias": {},
  "densities": [
    1,
    2
  ],
  "format": [
    "webp"
  ],
  "quality": 80
}

imageOptions.providers = {
  ['ipx']: { provider: ipxRuntime$h9c3T2RcKY, defaults: {} }
}
        