<template>
  <NuxtImg
    :src="src"
    :preset="presets"
    :alt="alt"
    :class="customClass"
    loading="lazy"
    :preload="preload"
  />
</template>

<script lang="ts">
export default {
  name: "CustomImage",
  props: {
    // URL source de l'image à afficher. Cette URL est traitée dans la computed property `imageUrl`.
    src: {
      type: String,
      required: true,
    },
    presets: {
      type: String,
      default: "default",
    },
    // Texte alternatif de l'image, utilisé pour l'accessibilité (a11y) et le SEO.
    alt: {
      type: String,
      default: "",
    },
    // Classe(s) CSS personnalisée(s) à appliquer à l'image, pour la personnalisation ou l'adaptabilité.
    customClass: {
      type: String,
      default: "",
    },
    preload: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
